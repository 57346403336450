<template>
	<Menu />
    <div class="content container">
        <img alt="BL logo" src="@/assets/logo_ncg.png" />
        <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane label="Vos polices" name="second">
                    <el-button @click="routerPush('NCGPolice')">Augmenter la fenêtre</el-button>
                    <iframe class="airtable-embed" src="https://airtable.com/embed/shrUhe58Hvz7ZABYr?backgroundColor=teal&viewControls=on"></iframe>
            </el-tab-pane>
            <el-tab-pane label="Vos sinistres" name="third">
                    <el-button @click="routerPush('NCGSinistre')">Augmenter la fenêtre</el-button>
                    <iframe class="airtable-embed" src="https://airtable.com/embed/shrx9NKk7D6DYUf8v?backgroundColor=teal&viewControls=on"></iframe>
            </el-tab-pane>
            <el-tab-pane label="Déclaration de sinistre" name="first">
                    <el-button @click="routerPush('NCGDeclaration')">Augmenter la fenêtre</el-button>
                    <iframe class="airtable-embed" src="https://airtable.com/embed/shrI6AWSE6qTZLcsW?backgroundColor=teal"></iframe>
            </el-tab-pane>
            <el-tab-pane label="" name="fourth">
                <template #label>
                    <span class="item">Assurance RCCS</span>
                </template>
                <el-button @click="routerPush('NCGAssurance')">Augmenter la fenêtre</el-button>
                <iframe class="airtable-embed" src="https://airtable.com/embed/shr5NKuSr8IetRacL?backgroundColor=pinkLight"></iframe>
            </el-tab-pane>
        </el-tabs>
    </div>
	<Footer />
</template>

<script>
import Menu from "@/components/organism/Menu.vue";
import Footer from "@/components/organism/Footer.vue";
import IFrame from "@/views/IFrame.vue"
export default {
	components: {
		Menu,
		Footer,
        IFrame,
	},
    data(){
        return {
            activeName: "second"
        }
    },
    methods: {
        routerPush(name){
            console.log('toto')
            const routes = {
                'NCGPolice': '/ncg-police',
                'NCGSinistre': '/ncg-sinistre',
                'NCGDeclaration': '/ncg-declaration',
                'NCGAssurance': '/ncg-assurance',
            }
            this.$router.push({path:routes[name]})
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.content{
    height:120vh;
    margin-top:80px;
    img {
        margin-bottom:40px;
    }
}

.demo-tabs {
    height: 100%;
    button {
        float:right;
        margin:10px;
    }
}
.airtable-embed {
	padding-top: 0px;
	background: transparent;
	border: 0px solid #ccc;
	height: 70vh;
	width: 100%;
}

.item {
    position:relative;
  &:before {
      content: "Beta";
      position:absolute;
      top:-5px;
      right:-5px;
      width:5px;
      height:5px;
      border-radius:100%;
      color: white;
      background-color:$secondary;
  }
}

// https://airtable.com/embed/shrx9NKk7D6DYUf8v?backgroundColor=teal&viewControls=on
// https://airtable.com/embed/shrUhe58Hvz7ZABYr?backgroundColor=teal&viewControls=on
// https://airtable.com/embed/shrI6AWSE6qTZLcsW?backgroundColor=teal
</style>
