<template>
  <div class="type-form">
    <div class="inner-img">
      <img alt="Building" src="@/assets/building.jpg" />
    </div>
    <div v-if="step == 'yousign'" class="yousign_iframe">
      <iframe
        :src="`https://webapp.yousign.com/procedure/sign?members=${yousign_iframe}&signatureUi=/signature_uis/c6a4d0f2-7d55-4611-8b4d-26cc1a697229`"
      ></iframe>
    </div>
    <div class="container inner-form" data-aos="fade-down">
      <transition name="el-fade-in-linear">
        <section class="section-typeform" v-show="step == 'title'">
          <h1>Responsabilité des Membres du Conseil Syndical</h1>
          <p>
            Ce produit couvre la responsabilité civile des conseillers syndicaux
          </p>
          <p>
            Après avoir rempli ce formulaire, si vous êtes éligible, vous
            recevrez la proposition d'assurance pour signature. ✍️
          </p>
          <div class="flex-row">
            <Button class="soumettre" :fn="nextStep">Commencer</Button>
          </div>
        </section>
      </transition>

      <transition name="el-fade-in-linear">
        <section class="section-typeform" v-show="step == 'selection'">
          <h2>Sélectionner votre copropriété</h2>
          <p>
            Afin de vous aider dans le remplissage des informations, utilisez
            notre moteur de recherche de copropriétés.
          </p>
          <p class="footnote">
            ⚠️ Les informations ne sont pas toujours pleinement à jour.
            Attention à les valider vous-mêmes ⚠️
          </p>
          <!-- <p class="footnote">
            Si vous ne trouvez pas votre copropriété, vous pouvez retrouver directement les informations dans l'<a href="https://www.registre-coproprietes.gouv.fr/annuaire">annuaire des copropriétés</a>.
          </p> -->
          <div class="question w-50">
            <div class="label">Rechercher votre copropriété</div>
            <el-select
              class="ass-input"
              v-model="elasticCoproprieteSelected"
              filterable
              remote
              placeholder="Rechercher ici"
              :remote-method="elasticCoproprieteSearch"
              :loading="loading"
              ref="select"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              multiple
              :multiple-limit="1"
            >
              <el-option
                v-for="(item, index) in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                style="height: auto"
                :class="`select_elastic ${index % 2 ? 'uneven' : ''}`"
              >
                <table class="table_elastic">
                  <div>
                    <div class="nom">{{ item.nom_copropriete }}</div>
                    <div class="syndic">{{ item.syndic }}</div>
                  </div>
                  <div>
                    <div class="address">{{ item.address }}</div>
                    <div class="numero_immat">{{ item.immatcopropriete__identification__numimmatriculationcopropriete }}</div>
                  </div>

                </table>
              </el-option>
            </el-select>
          </div>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" :fn="nextStep">Suivant</Button>
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section class="section-typeform" v-show="step == 'identite-proposant'">
          <h2>1. Identité du Proposant</h2>
          <el-form ref="identite_proposant" :model="answers" :rules="rules">
            <div class="question w-50">
              <div class="label">Nom d’usage de la copropriété concernée</div>
              <el-form-item prop="Nom Copropriété">
                <el-input
                  class="ass-input"
                  v-model="answers['Nom Copropriété']"
                  placeholder
                />
              </el-form-item>
            </div>

            <div class="question w-50">
              <div class="label">
                Adresse de référence de la copropriété concernée
              </div>
              <el-form-item prop="Adresse Copropriété">
                <el-input
                  class="ass-input"
                  v-model="answers['Adresse Copropriété']"
                  placeholder
                />
              </el-form-item>
            </div>

            <div class="question w-50">
              <div class="label">Numéro d’immatriculation</div>
              <!-- <div class="help">
                Si vous ne l'avez pas, retrouvez sur
                <a
                  href="https://www.registre-coproprietes.gouv.fr/annuaire"
                  target="_blank"
                  >l'annuaire des registres de copropriétés</a
                >
              </div> -->
              <el-form-item prop="No immatriculation">
                <el-input
                  class="ass-input"
                  v-model="answers['No immatriculation']"
                  placeholder
                />
              </el-form-item>
            </div>

            <div class="question w-50">
              <div class="label">
                Nombre de lots principaux à usage d’habitation, de commerces et
                de bureaux de la copropriété concernée
              </div>
              <el-form-item prop="nb_lots">
                <el-input
                  class="ass-input"
                  v-model="answers.nb_lots"
                  placeholder
                />
              </el-form-item>
            </div>
          </el-form>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" @click="nextStep('identite_proposant')"
              >Suivant</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section class="section-typeform" v-show="step == 'representant-legal'">
          <h2>Identification du représentant légal</h2>
          <el-form ref="representant-legal" :model="answers" :rules="rules">
            <div class="question w-50">
              <div class="label">Nom</div>
              <el-form-item prop="Nom représentant légal">
                <el-input
                  class="ass-input"
                  v-model="answers['Nom représentant légal']"
                  placeholder
                />
              </el-form-item>
            </div>
            <div class="question w-50 select-input">
              <div class="label">Agissant dans le cadre</div>
              <el-form-item class="select-input" prop="Contexte_représentation">
                <el-select
                  v-model="answers['Contexte_représentation']"
                  placeholder="Choisissez le type de mandat"
                  class="ass-input"
                  filterable
                >
                  <el-option
                    v-for="item in options_mandat"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="question w-50">
              <div class="label">Adresse</div>
              <el-form-item prop="Adresse représentant">
                <el-input
                  class="ass-input"
                  v-model="answers['Adresse représentant']"
                  placeholder
                />
              </el-form-item>
            </div>
            <div class="question w-50">
              <div class="label">Code SIRET</div>
              <el-form-item prop="SIRET représentant légal">
                <el-input
                  class="ass-input"
                  v-model="answers['SIRET représentant légal']"
                  placeholder
                />
              </el-form-item>
            </div>

            <div class="question w-50">
              <div class="label">Email</div>
              <el-form-item prop="Email représentant légal">
                <el-input
                  class="ass-input"
                  v-model="answers['Email représentant légal']"
                  placeholder
                />
              </el-form-item>
            </div>
          </el-form>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" @click="nextStep('representant-legal')"
              >Suivant</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section
          class="section-typeform"
          v-show="step == 'critere-eligibilite-1'"
        >
          <h2>2. Les critères d'éligibilité</h2>
          <p>
            Si un seul des critères ci-dessous n’est pas respecté, le Proposant
            ne peut pas bénéficier de la présente offre Responsabilité des
            Membres du Conseil Syndical de Copropriété.
          </p>
          <el-form ref="critere-eligibilite-1" :model="answers" :rules="rules">
            <div class="question w-50">
              <div class="label">
                La copropriété est-elle un Syndicat de Copropriétaires ?
              </div>
              <div class="yes-no-choice">
                <el-form-item prop="q_syndicat_copropriétaires">
                  <el-radio
                    v-model="answers.q_syndicat_copropriétaires"
                    label="Oui"
                    size="large"
                    border
                    >Oui</el-radio
                  >
                  <el-radio
                    v-model="answers.q_syndicat_copropriétaires"
                    label="Non"
                    size="large"
                    border
                    >Non</el-radio
                  >
                </el-form-item>
              </div>
            </div>
            <div class="question w-50">
              <div class="label">
                La copropriété est-t-elle située en France Métropolitaine, en
                Martinique, à la Guadeloupe ou à La Réunion ?
              </div>
              <div class="yes-no-choice">
                <el-form-item prop="q_domiciliation_france">
                  <el-radio
                    v-model="answers.q_domiciliation_france"
                    label="Oui"
                    size="large"
                    border
                    >Oui</el-radio
                  >
                  <el-radio
                    v-model="answers.q_domiciliation_france"
                    label="Non"
                    size="large"
                    border
                    >Non</el-radio
                  >
                </el-form-item>
              </div>
            </div>
            <div class="question w-50">
              <div class="label">
                Confirmez-vous ne pas faire l’objet d’une procédure
                administrative ?
              </div>
              <div class="help">
                (Mandat ad-hoc, Administrateur provisoire, Plan de sauvegarde ou
                État de carence)
              </div>
              <div class="yes-no-choice">
                <el-form-item prop="q_procedure_3ans">
                  <el-radio
                    v-model="answers.q_procedure_3ans"
                    label="Oui"
                    size="large"
                    border
                    >Oui</el-radio
                  >
                  <el-radio
                    v-model="answers.q_procedure_3ans"
                    label="Non"
                    size="large"
                    border
                    >Non</el-radio
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>

          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" @click="nextStep('critere-eligibilite-1')"
              >Suivant</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section
          class="section-typeform"
          v-show="step == 'critere-eligibilite-2'"
        >
          <h2>2. Les critères d'éligibilité</h2>
          <p>
            Si un seul des critères ci-dessous n’est pas respecté, le Proposant
            ne peut pas bénéficier de la présente offre Responsabilité des
            Membres du Conseil Syndical de Copropriété.
          </p>
          <el-form ref="critere-eligibilite-2" :model="answers" :rules="rules">
            <div class="question w-50" v-if="answers.nb_lots > 50">
              <div class="label">
                Si la copropriété concernée est composée de plus de 50 lots
                confirmez-vous ne pas avoir un taux d’impayés de charges
                supérieur à 25 % sur l’un des 3 derniers budgets votés ?
              </div>
              <div class="yes-no-choice">
                <el-form-item prop="q_taux_impayé">
                  <el-radio
                    v-model="answers.q_taux_impayé"
                    label="Oui"
                    size="large"
                    border
                    >Oui</el-radio
                  >
                  <el-radio
                    v-model="answers.q_taux_impayé"
                    label="Non"
                    size="large"
                    border
                    >Non</el-radio
                  >
                </el-form-item>
              </div>
            </div>
            <div class="question w-50">
              <div class="label">
                Confirmez-vous ne pas avoir connaissance, après vérification, de
                réclamation en cours, amiable ou judiciaire faite à votre
                encontre ou à l’encontre des membres du conseil syndical de la
                copropriété concernée susceptibles de mettre en jeu votre /leur
                responsabilité civile ou pénale ?
              </div>
              <div class="yes-no-choice">
                <el-form-item prop="q_abs_reclamation">
                  <el-radio
                    v-model="answers.q_abs_reclamation"
                    label="Oui"
                    size="large"
                    border
                    >Oui</el-radio
                  >
                  <el-radio
                    v-model="answers.q_abs_reclamation"
                    label="Non"
                    size="large"
                    border
                    >Non</el-radio
                  >
                </el-form-item>
              </div>
            </div>
            <div class="question w-50">
              <div class="label">
                Confirmez-vous ne pas avoir été assuré par le passé par un
                contrat d’assurance souscrit auprès d’une société du groupe AIG
                couvrant la Responsabilité des Membres du Conseil Syndical ?
              </div>
              <div class="yes-no-choice">
                <el-form-item prop="q_abs_assurance_antérieure">
                  <el-radio
                    v-model="answers.q_abs_assurance_antérieure"
                    label="Oui"
                    size="large"
                    border
                    >Oui</el-radio
                  >
                  <el-radio
                    v-model="answers.q_abs_assurance_antérieure"
                    label="Non"
                    size="large"
                    border
                    >Non</el-radio
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" @click="nextStep('critere-eligibilite-2')"
              >Suivant</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section class="section-typeform" v-show="step == 'documents'">
          <h2>
            Prenez connaissance des documents de souscription
            <span v-if="isPartenaire">- Offre Partenaire</span>
          </h2>
          <p>
            Au vu des informations que vous avez déclaré, vous pouvez souscrire
            à l'assurance à partir de
            <b>{{ getPrix[0].prime }}</b
            >.
          </p>

          <p v-if="isPartenaire">
            Votre syndic est identifié comme un syndic partenaire d'Assynco.
            Aussi, vous bénéficiez d'une réduction de <b>13%</b> sur le montant
            de la prime d'assurance!
          </p>
          <p>Veuillez trouver ci-après:</p>
          <ul class="documents">
            <li>
              <paperclip-icon class="icon"></paperclip-icon>
              <!-- href="https://docs.google.com/uc?export=download&id=1O_8bUGZf2yx1SDr-3i9zb5et_zJNHozS" -->
              <a @click="downloadPreFilled"
                >le bulletin de souscription pré-rempli</a
              >
            </li>
            <li>
              <paperclip-icon class="icon"></paperclip-icon>
              <a
                href="https://docs.google.com/uc?export=download&id=1VNFVdnPkCnQfoo3rJ3imqsaeR-Ks-ins"
                >les Conditions Générales</a
              >
            </li>
            <li>
              <paperclip-icon class="icon"></paperclip-icon>
              <a
                href="https://docs.google.com/uc?export=download&id=1JQd3Un3mD1YejxqsPxYhOZ6eonnytDWJ"
                >la fiche IPID de l'assurance</a
              >
            </li>
          </ul>
          <p>
            Merci d'en prendre connaissance avant de poursuivre dans le
            processus de souscription ! 👀
          </p>
          <p>
            Cliquez sur suivant pour définir les derniers paramètres de votre
            assurance et procéder à la souscription!
          </p>
          <p class="mt-3">
            Envoyer le <b>projet de souscription d'assurance</b> par mail à l'adresse suivante:
          </p>
          <div class="flex-row mail_send">
            <div class="question w-50">
              <div class="label">Email</div>
              <el-form-item prop="send_to">
                <el-input
                  class="ass-input"
                  v-model="send_to"
                  placeholder
                />
              </el-form-item>
            </div>
            <Button class="secondary" :fn="send_to_fn">
              <div v-if="sent == false">Envoyer</div>
              <div v-if="sent == 'pending'"><img alt="loading" src="@/assets/loading.svg" /></div>
              <div v-if="sent == true">Envoyé!</div>
            </Button>
          </div>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" :fn="nextStep">Suivant</Button>
          </div>
        </section>
      </transition>
      <!-- <transition name="el-fade-in-linear"> -->
      <transition name="el-fade-in-linear">
        <section
          class="section-typeform ChoixOption"
          v-show="step == 'choix-option'"
        >
          <h2>3. Montant des garanties et prime</h2>
          <div class="question">
            <div class="label">Sélection du Plafond des Garanties et Prime</div>
            <el-form ref="choix-option" :model="answers" :rules="rules">
              <div class="garantie__table_header">
                <el-form-item prop="api_choix_no">
                  <el-table :data="getPrix" style="width: 100%">
                    <el-table-column label width="180">
                      <template #default="scope">
                        <div style="display: flex; align-items: center">
                          <el-radio
                            v-model="answers.api_choix_no"
                            :label="scope.row.option"
                            size="large"
                            border
                            >Option {{ scope.row.optionShow }}</el-radio
                          >
                        </div>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column
                  prop="lot"
                  label="Nombre de lots maximum"
                  class="garantie__table_header"
                /> -->
                    <el-table-column
                      prop="plafond"
                      label="Plafond des garanties par période d'assurance"
                      class="garantie__table_header"
                    />
                    <el-table-column
                      prop="prime"
                      label="Montant de la prime annuelle TTC"
                      class="garantie__table_header"
                    />
                    <el-table-column
                      prop="fees"
                      label="dont frais de gestion"
                      class="garantie__table_header"
                    />
                  </el-table>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" @click="nextStep('choix-option')"
              >Suivant</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section
          class="section-typeform ChoixOption"
          v-show="step == 'choix-parametres'"
        >
          <h2>4. Période d’assurance</h2>
          <el-form ref="choix-parametres" :model="answers" :rules="rules">
            <div class="question">
              <div class="label">Date d’effet des garanties</div>
              <div class="help">
                *Cette date ne peut être antérieure à la date de signature de la
                présente proposition ou postérieure de plus de 120 jours.
              </div>
              <div class="yes-no-choice">
                <el-form-item prop="api_choix_date_prise_effet">
                  <div>
                    <el-scrollbar always>
                      <el-radio
                        v-model="answers.api_choix_date_prise_effet"
                        label="Au lendemain, 0h de la signature de la proposition"
                        size="large"
                        border
                        >Au lendemain zéro heure de la date de signature de la
                        présente proposition</el-radio
                      >
                    </el-scrollbar>
                    <el-scrollbar always>
                      <div class="second">
                        <el-radio
                          v-model="answers.api_choix_date_prise_effet"
                          label="À la date souhaitée par le proposant"
                          size="large"
                          border
                          >À la date souhaitée par le proposant</el-radio
                        >
                      </div>
                    </el-scrollbar>
                  </div>
                </el-form-item>
              </div>
            </div>
            <div
              v-if="
                answers.api_choix_date_prise_effet ==
                'À la date souhaitée par le proposant'
              "
              class="question w-50"
            >
              <div class="label">Choix de la date de prise d'effet</div>
              <div class="help">
                *Cette date ne peut être antérieure à la date de signature de la
                présente proposition ou postérieure de plus de 120 jours.
              </div>
              <el-form-item prop="date_effet">
                <el-date-picker
                  class="ass-input"
                  v-model="answers.date_effet"
                  type="date"
                  placeholder="Ex. 12/02/2022"
                  format="DD/MM/YYYY"
                ></el-date-picker>
              </el-form-item>
            </div>

            <div class="question w-50">
              <div class="label">Date d'échéance du contrat</div>
              <div class="help">
                *Cette date ne peut être antérieure à la date de signature de la
                présente proposition ou postérieure de plus de 120 jours.
              </div>
              <div class="yes-no-choice">
                <el-form-item prop="api_choix_echeance">
                  <div>
                    <el-scrollbar always>
                      <el-radio
                        v-model="answers.api_choix_echeance"
                        label="À la date anniversaire de la prise d’effet de la garantie"
                        size="large"
                        border
                        >À la date anniversaire de la prise d’effet de la
                        garantie</el-radio
                      >
                    </el-scrollbar>
                    <el-scrollbar always>
                      <div class="second">
                        <el-radio
                          v-model="answers.api_choix_echeance"
                          label="À une date définie"
                          size="large"
                          border
                          >À une date définie</el-radio
                        >
                      </div>
                    </el-scrollbar>
                  </div>
                </el-form-item>
              </div>
            </div>

            <transition name="slide-fade">
              <div
                v-if="answers.api_choix_echeance == 'À une date définie'"
                class="question date-question w-50"
              >
                <div class="label">Choix de la date d'échéance</div>
                <div class="help">
                  *La première période d’assurance ne pourra être inférieure à 6
                  mois ni dépasser 18 mois.
                </div>
                <el-form-item prop="date_echeance">
                  <el-date-picker
                    class="ass-input"
                    v-model="answers['date_echeance']"
                    type="date"
                    placeholder="Ex. 12/02/2022"
                    format="DD/MM/YYYY"
                  />
                </el-form-item>
              </div>
            </transition>
          </el-form>

          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" @click="nextStep('choix-parametres')"
              >Suivant</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section
          class="section-typeform Confirmation"
          v-show="step == 'confirmation'"
        >
          <h2>5. Déclarations du signataire</h2>
          <div class="question checkbox">
            <p class="help">Le Proposant déclare :</p>
            <el-form ref="confirmation" :model="answers" :rules="rules">
              <el-form-item prop="q_respect_egibilite">
                <el-checkbox
                  class="checkbox-item"
                  v-model="answers.q_respect_egibilite"
                  size="large"
                  >Respecter les critères d’éligibilité précités</el-checkbox
                >
              </el-form-item>
              <el-form-item prop="q_connaissance">
                <el-checkbox
                  class="checkbox-item"
                  v-model="answers.q_connaissance"
                  size="large"
                  >Avoir reçu et pris connaissance de la fiche d’information
                  relative au fonctionnement des garanties « responsabilité
                  civile » dans le temps</el-checkbox
                >
              </el-form-item>
              <el-form-item prop="q_renseignements">
                <el-checkbox
                  class="checkbox-item"
                  v-model="answers.q_renseignements"
                  size="large"
                >
                  Que les renseignements communiqués par ce document sont exacts
                  et qu’il n’a volontairement omis ou supprimé aucun fait.
                  <br />En cas de déclaration inexacte et intentionnelle
                  changeant l’objet du risque ou diminuant l’opinion que
                  l’assureur a pu s’en faire, les dispositions prévues à
                  l’article l.113-8 du code des assurances seront appliquées
                </el-checkbox>
              </el-form-item>
              <el-form-item prop="q_connaissance_cg">
                <el-checkbox
                  class="checkbox-item"
                  v-model="answers.q_connaissance_cg"
                  size="large"
                  >Avoir préalablement reçu, pris connaissance et accepté les
                  conditions générales référencées CG Responsabilité des Membres
                  du Conseil Syndical de copropriété jointes à la présente
                  proposition. En cas d’acceptation du risque par l’assureur,
                  les conditions Particulières et générales émises sur la base
                  de la présente proposition constitueront le contrat
                  d’assurance</el-checkbox
                >
              </el-form-item>
              <el-form-item prop="q_circonstances">
                <el-checkbox
                  class="checkbox-item"
                  v-model="answers.q_circonstances"
                  size="large"
                  >S’engager à déclarer toutes circonstances nouvelles modifiant
                  les déclarations faites dans la présente proposition qui
                  pourraient survenir entre ce jour et la date de prise d’effet
                  de sa police d’assurance ou postérieurement à la date de cette
                  prise d’effet, notamment toutes modifications des critères
                  d’éligibilité</el-checkbox
                >
              </el-form-item>
              <el-form-item prop="q_mandat_exclusif">
                <el-checkbox
                  class="checkbox-item"
                  v-model="answers.q_mandat_exclusif"
                  size="large"
                  >Donner au partenaire mentionné en en-tête de la présente
                  proposition, mandat exclusif de placement des garanties
                  souscrites auprès de la compagnie AIG, le présent mandat
                  annulant tout mandat et/ou instructions
                  précédents</el-checkbox
                >
              </el-form-item>
              <el-form-item prop="souhait_souscrire_ass">
                <el-checkbox
                  class="checkbox-item"
                  label="true"
                  size="large"
                  v-model="answers['souhait_souscrire_ass']"
                  >Vouloir souscrire le contrat Responsabilité des Membres du
                  Conseil Syndical de copropriété.</el-checkbox
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" @click="nextStep('confirmation')"
              >Suivant</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section
          class="section-typeform Confirmation"
          v-show="step == 'confirmation-2'"
        >
          <h2>6. Identification du signataire</h2>
          <p>
            Vous pourrez signer en version signature électronique le bulletin de
            souscription, avec le prénom, nom et adresse email du signataire
            renseigné ci-dessous.
          </p>
          <el-form ref="confirmation-2" :model="answers" :rules="rules">
            <div class="question w-50">
              <div class="label">Prénom</div>
              <el-form-item prop="Signataire_Prénom">
                <el-input
                  class="ass-input"
                  v-model="answers.Signataire_Prénom"
                  placeholder
                />
              </el-form-item>
            </div>
            <div class="question w-50">
              <div class="label">Nom</div>
              <el-form-item prop="Signataire_Nom">
                <el-input
                  class="ass-input"
                  v-model="answers.Signataire_Nom"
                  placeholder
                />
              </el-form-item>
            </div>
            <div class="question w-50">
              <div class="label">En qualité de</div>
              <el-form-item prop="Signataire_qualite">
                <el-input
                  class="ass-input"
                  v-model="answers.Signataire_qualite"
                  placeholder="Syndic ou membre du Conseil Syndical"
                />
              </el-form-item>
            </div>
            <div class="question w-50">
              <div class="label">Adresse email</div>
              <div class="help">
                Cette addresse email sera utilisée pour vous envoyer votre
                bulletin de souscription en signature.
              </div>
              <el-form-item prop="Signataire_Email">
                <el-input
                  class="ass-input"
                  v-model="answers.Signataire_Email"
                  placeholder
                />
              </el-form-item>
            </div>
            <div class="question w-50">
              <div class="label">Numéro de téléphone</div>
              <el-form-item prop="Signataire_tel">
                <el-input
                  class="ass-input"
                  v-model="answers.Signataire_tel"
                  placeholder
                />
              </el-form-item>
            </div>
          </el-form>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retour</Button
            >
            <Button class="soumettre" @click="nextStep('confirmation-2')"
              >Suivant</Button
            >
          </div>
        </section>
      </transition>

      <transition name="el-fade-in-linear">
        <section class="section-typeform success" v-if="step == 'yousign'">
          <h2>Signature des documents de souscription</h2>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section class="section-typeform success" v-show="step == 'success'">
          <h2>Demande de souscription terminée</h2>
          <p>L'équipe Assynco vous remercie pour votre confiance!</p>
          <p>
            Vous recevrez vos pièces directement dans votre boîte mail!📫
            <br />
            <br />Vous serez pleinement assuré par le renvoi par l'assureur des
            Conditions Particulières signées.✍️
          </p>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retourner sur Assynco</Button
            >
            <Button class="soumettre" :fn="nextStep"
              >Déposer une nouvelle demande</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section class="section-typeform success" v-show="step == 'success-2'">
          <h2>Demande de souscription terminée</h2>
          <p>Nous vous remercions pour ces informations!</p>
          <p>
            Vous allez recevoir à votre adresse mail une demande de signature
            des documents de souscription.📫
            <br />
            <br />Vous serez pleinement assuré par le renvoi par l'assureur des
            Conditions Particulières signées.✍️
          </p>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Retourner sur Assynco</Button
            >
            <Button class="soumettre" :fn="nextStep"
              >Déposer une nouvelle demande</Button
            >
          </div>
        </section>
      </transition>

      <transition name="el-fade-in-linear">
        <section class="section-typeform error" v-show="step == 'error'">
          <h2>Malheureusement, vous n'êtes pas éligible 😢</h2>
          <p>
            Vous ne respectez pas les critères d'éligibilité sur la base des
            informations fournies ou celles contenues dans le registre de
            copropriété✍️
          </p>
          <p>Nous vous remercions de votre démarche!</p>
          <p>
            Merci de
            <a href="https://airtable.com/shrcmE7dpUBW9qB4O" target="_blank"
              >nous contacter</a
            >
            pour davantage d'informations.📬
          </p>
          <div class="flex-row">
            <Button class="soumettre" classType="secondary" :fn="previousStep"
              >Corriger mes informations</Button
            >
            <Button class="soumettre" :fn="nextStep"
              >Déposer une nouvelle demande</Button
            >
          </div>
        </section>
      </transition>
      <transition name="el-fade-in-linear">
        <section v-show="step == 'loading'">

        <div class="section-loading" v-show="step == 'loading'">
          <img alt="loading" src="@/assets/loading.svg" />
        </div>
          <div class="section-loading-message">
            <div>
              {{loadingMessage}}
            </div>
            <div>
              {{subLoadingMessage}}
            </div>
            </div>
        </section>
      </transition>
    </div>
    <img
      @click="pushTo('Home')"
      class="logo"
      alt="BL logo"
      src="@/assets/logo-assynco.svg"
    />
  </div>
  <!-- <Footer /> -->
</template>

<script>
import Button from "@/components/atoms/Button.vue";
import Footer from "@/components/organism/Footer.vue";
import Menu from "@/components/molecules/Menu.vue";
import router from "@/router";

const steps = [
  "title",
  "selection",
  "identite-proposant",
  "representant-legal",
  "critere-eligibilite-1",
  "critere-eligibilite-2",
  "documents",
  "choix-option",
  "choix-parametres",
  "confirmation",
  "confirmation-2",
  "yousign",
  "success",
  "error",
  "loading",
];

const prixPublic = [
  {
    value: "",
    lot: "10",
    plafond: "50 000€",
    prime: "208,96€",
    fees: "24,80€",
    prime_partenaire: "181,70€",
    fees_partenaire: "21,57€",
    option: 1,
    optionShow: 1,
  },
  {
    value: "",
    lot: "49",
    plafond: "100 000€",
    prime: "271,11€",
    fees: "32,18€",
    prime_partenaire: "235,75€",
    fees_partenaire: "27,98€",
    option: 2,
    optionShow: 2,
  },
  {
    value: "",
    lot: "199",
    plafond: "250 000€",
    prime: "527,68€",
    fees: "62,63",
    prime_partenaire: "458,85€",
    fees_partenaire: "54,46€",
    option: 3,
    optionShow: 3,
  },
  {
    value: "",
    lot: "499",
    plafond: "500 000€",
    prime: "670,51€",
    fees: "79,59€",
    prime_partenaire: "583,05€",
    fees_partenaire: "69,21€",
    option: 4,
    optionShow: 4,
  },
  {
    value: "",
    lot: "999",
    plafond: "750 000€",
    prime: "911,20€",
    fees: "108,16€",
    prime_partenaire: "792,35€",
    fees_partenaire: "69,21€",
    option: 5,
    optionShow: 5,
  },
  {
    value: "",
    lot: "Autres",
    plafond: "1 000 000€",
    prime: "990,55€",
    fees: "117,57€",
    prime_partenaire: "861,35€",
    fees_partenaire: "102,24€",
    option: 6,
    optionShow: 6,
  },
];

const options_mandat = [
  {
    value: "Syndic professionnel",
    label: "Syndic professionnel",
  },
  {
    value: "Syndic coopératif",
    label: "Syndic coopératif",
  },
  {
    value: "Résidence de service",
    label: "Résidence de service",
  },
];

const required_rule = [
  {
    required: true,
    message: "Ce champ est obligatoire",
    // validator: (rule, value, cb) => {
    //   console.log(value);
    //   if (value == "toto") cb();
    //   cb(new Error("Please input the password"));
    // },
    trigger: "blur",
  },
];

const required_rule_checkbox = [
  {
    // required: true,
    // message: "Ce champ est obligatoire",
    validator: (rule, value, cb) => {
      console.log(value);
      if (value == true) cb();
      cb(new Error("Cochez cette case pour poursuivre"));
    },
    trigger: "blur",
  },
];

// const RECORD_ID = "recgsNOV4vSMiqIs8"
const RECORD_ID = ""
export default {
  components: {
    Button,
    Footer,
    Menu,
  },

  data() {
    return {
      loading: false,
      send_to: "frederic.bertoia@equisafe.io",
      sent: false,
      loadingMessage: "⏰ Nous générons la procédure de signature du bulletin de souscription.",
      subLoadingMessage : "Ce processus peut prendre quelques secondes.",
      polling: null,
      yousign_iframe: "",
      elasticCoproprieteSelected: "",
      coproprieteSelected: {},
      hiddenForm: false,
      options: [],
      prixPublic,
      options_mandat,
      record_id: RECORD_ID,
      ruleFormRef: "",
      rules: {
        "Nom Copropriété": [
          {
            required: true,
            message: "Ce champ est obligatoire",
            // validator: (rule, value, cb) => {
            //   console.log(value);
            //   if (value == "toto") cb();
            //   cb(new Error("Please input the password"));
            // },
            trigger: "blur",
          },
        ],
        "Adresse Copropriété": required_rule,
        "No immatriculation": required_rule,
        nb_lots: required_rule,
        "Nom représentant légal": required_rule,
        Contexte_représentation: required_rule, //Mandat de syndic
        "Adresse représentant": required_rule,
        "SIRET représentant légal": required_rule,
        "Email représentant légal": required_rule,
        q_syndicat_copropriétaires: required_rule,
        q_domiciliation_france: required_rule,
        q_procedure_3ans: required_rule,
        q_taux_impayé: required_rule,
        q_abs_reclamation: required_rule,
        q_abs_assurance_antérieure: required_rule,
        api_choix_no: [
          { ...required_rule[0], message: "Sélectionnez une option" },
        ],
        date_effet: required_rule,
        api_choix_date_prise_effet: required_rule,
        date_echeance: required_rule,
        api_choix_echeance: required_rule,
        Signataire_Prénom: required_rule,
        Signataire_Nom: required_rule,
        Signataire_Email: required_rule,
        Signataire_tel: required_rule,
        Signataire_qualite: required_rule,
        q_respect_egibilite: required_rule_checkbox,
        q_connaissance: required_rule_checkbox,
        q_renseignements: required_rule_checkbox,
        q_connaissance_cg: required_rule_checkbox,
        q_circonstances: required_rule_checkbox,
        q_mandat_exclusif: required_rule_checkbox,
        souhait_souscrire_ass: required_rule_checkbox,
      },
      answers: {
        // "Adresse Copropriété": "25 rue saint Gabriel 14000 Caen",
        // "Adresse représentant": "",
        // "Email représentant légal": "frederic.bertoia@gmail.com",
        // "No immatriculation": "98798798",
        // "Nom Copropriété": "Bertoia",
        // "Nom représentant légal": "Bertoia&Co",
        // "SIRET représentant légal": "987588897",
        // Signataire_Email: "frederic.bertoia@gmail.com",
        // Signataire_Nom: "Bertoïa",
        // Signataire_Prénom: "Frédéric",
        // api_choix_date_prise_effet:
        //   "Au lendemain, 0h de la signature de la proposition",
        // date_effet: "",
        // api_choix_echeance:
        //   "À la date anniversaire de la prise d’effet de la garantie",
        // date_echeance: "",
        // api_choix_no: 4,
        // nb_lots: 58,
        // q_abs_assurance_antérieure: "Oui",
        // q_abs_reclamation: "Oui",
        // q_domiciliation_france: "Oui",
        // q_procedure_3ans: "Oui",
        // q_syndicat_copropriétaires: "Oui",
        // q_taux_impayé: "Oui",
        // q_respect_egibilite: true,
        // q_connaissance: true,
        // q_renseignements: true,
        // q_connaissance_cg: true,
        // q_circonstances: true,
        // q_mandat_exclusif: true,
        // souhait_souscrire_ass: true,
        "Nom Copropriété": "",
        "Adresse Copropriété": "",
        "No immatriculation": "",
        nb_lots: "",
        "Nom représentant légal": "",
        Contexte_représentation: "", //Mandat de syndic
        "Adresse représentant": "",
        "SIRET représentant légal": "",
        "Email représentant légal": "",
        q_syndicat_copropriétaires: "",
        q_domiciliation_france: "",
        q_procedure_3ans: "",
        q_taux_impayé: "",
        q_abs_reclamation: "",
        q_abs_assurance_antérieure: "",
        api_choix_no: "",
        date_effet: "",
        api_choix_date_prise_effet: "",
        date_echeance: "",
        api_choix_echeance: "",
        Signataire_Prénom: "",
        Signataire_Nom: "",
        Signataire_Email: "",
        Signataire_tel: "",
        Signataire_qualite: "",
        q_respect_egibilite: false,
        q_connaissance: false,
        q_renseignements: false,
        q_connaissance_cg: false,
        q_circonstances: false,
        q_mandat_exclusif: false,
        souhait_souscrire_ass: false,
        partenaire: false,
        partenaire_type: "",

        /* Infos du syndic */
        flagEquipementsCom: false,
        flagInsalubritePartiesCom: false,
        flagMandatAdhoc: false,
        flagOrdonnanceCarence: false,
        flagPerilPartiesCom: false,
        Syndic_professionnel: false,
        Syndic_cooperatif: false,
        Residence_de_service: false,
        tauxImpayés: 0,
        adminProvisoire: false,
      },
      // answers2: {
      //   "Nom représentant légal": "",
      //   Contexte_représentation: "", //Mandat de syndic
      //   "Adresse représentant": "",
      //   "SIRET représentant légal": "",
      //   "Email représentant légal": "",
      // },
      stepList: ['title', 'selection', 'identite-proposant', 'representant-legal', 'critere-eligibilite', 'choix-option', 'confirmation', 'yousign', 'success', 'loading'],
      step: "title", // 'title', 'selection', 'identite-proposant', 'representant-legal', 'critere-eligibilite', 'choix-option', 'confirmation', 'yousign', 'success'
      prix_souscription: prixPublic,
    };
  },
  computed: {
    isPartenaire() {
      const partenaireNCG = ["78537645000017"];
      const partenaireImmoExpress = ["44520765700021"];
      if (partenaireNCG.includes(this.answers["SIRET représentant légal"])) {
        this.answers.partenaire = true;
        this.answers["Adresse représentant"] =
          "24 AV VICTOR CRESSON 92130 ISSY-LES-MOULINEAUX"; // NEOUZE - CLEMENT - GOUSSE
        this.answers.partenaire_type = "NCG";
        return true;
      } else if (
        partenaireImmoExpress.includes(this.answers["SIRET représentant légal"])
      ) {
        this.answers.partenaire = true;
        this.answers.partenaire_type = "Immo Express";
        this.answers["Adresse représentant"] =
          "128 RUE DU CHEVALERET 75013 PARIS"; // NEOUZE - CLEMENT - GOUSSE
        return true;
      }
      return false;
    },
    getPrix() {
      if (this.answers.nb_lots) {
        let ret = prixPublic.filter(
          (data) =>
            (Number(this.answers.nb_lots) < Number(data.lot)) |
            (data.lot === "Autres")
        );
        ret = ret
          .map((data, index) => ({ ...data, optionShow: index + 1 }))
          .map((data) => ({
            ...data,
            prime: this.isPartenaire ? data.prime_partenaire : data.prime,
            fees: this.isPartenaire ? data.fees_partenaire : data.fees,
          }));
        return ret;
      }
      return prixPublic;
    },
  },
  methods: {
    pushTo(name) {
      router.push({ name });
      window.scrollTo(0, 0);
    },
    async fetchCopropriete() {
      if (!Object.keys(this.elasticCoproprieteSelected).length)
        return
      const copro = await this.$api.getCopropriete(
        this.elasticCoproprieteSelected
      );
      this.coproprieteSelected = copro;
      console.log(copro);
      this.answers["Nom Copropriété"] =
        copro.immatCopropriete.identification.nomCopropriete;
      this.answers["Adresse Copropriété"] =
        copro.immatCopropriete.identification.adressePrincipale.flatAdresse;
      this.answers["No immatriculation"] =
        copro.immatCopropriete.identification.numImmatriculationCopropriete;
      this.answers["nb_lots"] =
        copro.immatCopropriete.identification.nbLotsHabBurCom;
      this.answers["Nom représentant légal"] = copro.raisonSocialeOuNomSyndic;
      this.answers["SIRET représentant légal"] = copro.siret;
      if (copro.typeTeledeclarant == "syndic professionnel") {
        this.answers["Contexte_représentation"] = "Syndic professionnel";
        this.answers["Syndic_professionnel"] = true;
      }
      if (
        copro.immatCopropriete.identification.syndicatCooperatif == "true" ||
        copro.typeTeledeclarant == "syndic bénévole" ||
        copro.typeTeledeclarant == "syndic coopératif"
      ) {
        this.answers["Contexte_représentation"] = "Syndic coopératif";
        this.answers["Syndic_cooperatif"] = true;
      }
      if (copro.immatCopropriete.identification.residenceService == "true") {
        this.answers["Contexte_représentation"] = "Résidence de service";
        this.answers["Residence_de_service"] = true;
      }
      this.answers.tauxImpayés =
        100 *
        (copro.immatCopropriete.donneesFinancieres
          .montantImpayesCoproprietaires /
          copro.immatCopropriete.donneesFinancieres.chargesOperationsCourantes);
      console.log(
        copro.immatCopropriete.donneesFinancieres.montantImpayesCoproprietaires
      );
      console.log(
        copro.immatCopropriete.donneesFinancieres.chargesOperationsCourantes
      );
      console.log(copro);
      this.answers.flagEquipementsCom =
        copro.immatCopropriete.procedures.flagEquipementsCom;
      this.answers.flagInsalubritePartiesCom =
        copro.immatCopropriete.procedures.flagInsalubritePartiesCom;
      this.answers.flagMandatAdhoc =
        copro.immatCopropriete.procedures.flagMandatAdhoc;
      this.answers.flagOrdonnanceCarence =
        copro.immatCopropriete.procedures.flagOrdonnanceCarence;
      this.answers.flagPerilPartiesCom =
        copro.immatCopropriete.procedures.flagPerilPartiesCom;
      this.answers.adminProvisoire =
        copro.immatCopropriete.infoRattachement.adminProvisoire == "true"
          ? true
          : false;
      // "Nom Copropriété": "",
      // "Adresse Copropriété": "",
      // "No immatriculation": "",
      // nb_lots: "",
      // "Nom représentant légal": "",
      // "Adresse représentant": "",
      // "SIRET représentant légal": "",
      // "Email représentant légal": "",
      // q_syndicat_copropriétaires: "",
      // q_domiciliation_france: "",
      // q_procedure_3ans: "",
      // q_taux_impayé: "",
      // q_abs_reclamation: "",
      // q_abs_assurance_antérieure: "",
      // api_choix_no: "",
      // "date_effet": "",
      // api_choix_date_prise_effet: "",
      // "date_echeance": "",
      // api_choix_echeance: "",
      // Signataire_Prénom: "",
      // Signataire_Nom: "",
      // Signataire_Email: "",
      // q_respect_egibilite: "",
      // q_connaissance: "",
      // q_renseignements: "",
      // q_connaissance_cg: "",
      // q_circonstances: "",
      // q_mandat_exclusif: "",
      // "souhait_souscrire_ass": "",
    },

    previousStep() {
      this.send_to = ""
      this.sent = false
      const step = this.step;
      const stepIndex = steps.findIndex((e) => e == this.step);
      if (this.step == "success") {
        router.push({ name: "Home" });
      } else if (this.step == "error") {
        this.step = "identite-proposant";
      } else if (stepIndex != 0) this.step = steps[stepIndex - 1];
    },

    async nextStep(formRef) {
      if (typeof formRef == "string") {
        let formValid = false;
        const valid = this.$refs[formRef].validate((valid, fields) => {
          if (valid) formValid = true;
        });
        if (!formValid) return;
      }
      const step = this.step;
      this.send_to = ""
      this.sent = false
      const stepIndex = steps.findIndex((e) => e == this.step);
      if (
        this.step == "selection" &&
        this.elasticCoproprieteSelected &&
        this.elasticCoproprieteSelected != " "
      ) {
        this.step = "loading";
        this.loadingMessage = "⏰ Nous récupérons les informations de votre copropriété."
        await this.fetchCopropriete();
        this.step = steps[stepIndex + 1];
      } else if (this.step == "critere-eligibilite-2") {
        this.step = "loading";
        this.loadingMessage = "⏰ Nous vérifions l'éligibilité de l'assurance au regard des informations communiquées."

        const ret = await this.sendInformation();
        console.log(this.answers);
        if (
          this.answers.q_syndicat_copropriétaires == "Non" ||
          this.answers.q_domiciliation_france == "Non" ||
          this.answers.q_procedure_3ans == "Non" ||
          this.answers.q_taux_impayé == "Non" ||
          this.answers.q_abs_reclamation == "Non" ||
          this.answers.q_abs_assurance_antérieure == "Non" ||
          this.answers.flagMandatAdhoc == true ||
          this.answers.flagOrdonnanceCarence == true ||
          this.answers.flagPerilPartiesCom == true ||
          this.answers.Syndic_cooperatif == true ||
          this.answers.Residence_de_service == true ||
          this.answers.adminProvisoire == true ||
          this.answers.Contexte_représentation != "Syndic professionnel" ||
          (this.answers.nb_lots >= 50 && this.answers.tauxImpayés >= 25)
        ) {
          this.step = "error";
        } else this.step = steps[stepIndex + 1];
      } else if (this.step == "confirmation-2") {
        this.step = "loading";
        let ret = await this.sendInformation();
        this.loadingMessage = "⏰ Nous générons la procédure de signature du bulletin de souscription."
        this.subLoadingMessage = "Ce processus peut prendre quelques secondes."
        console.log(ret);
        
        ret = await this.generateDocumentSouscription();
        ret = await this.generateSignatureSouscription();

        this.step = steps[stepIndex + 1];
        this.loadingMessage = ""
        this.subLoadingMessage = ""
      } else if ((this.step == "success") | (this.step == "error")) {
        console.log("test");
        this.flush();
        this.step = steps[0];
      } else {
        this.step = steps[stepIndex + 1];
      }
    },

    async generateSignatureSouscription() {
      const ret = await this.$api.generateSignatureSouscription(this.record_id);
      this.yousign_iframe = ret.data.fields["Yousign members"];
    },
    async generateDocumentSouscription() {
      return this.$api.generateDocumentSouscription(this.record_id);
    },
    async sendInformation() {
      const url = `${this.$env.URL_API}/rccs/${this.record_id}`;
      let data = { ...this.answers };
      if (data["date_effet"] == "") data = { ...data, date_effet: null };
      if (data["date_echeance"] == "") data = { ...data, date_echeance: null };
      console.log(data);
      const souscriptionId = await this.$api.updateSouscription(
        this.record_id,
        data
      );
      console.log(souscriptionId);
      if (souscriptionId) this.record_id = souscriptionId;
    },
    async elasticCoproprieteSearch(query) {
      if (!this.options) {
        this.loadingMessage = "Nous recherchons les informations de votre copropriété."
        this.loading = true;
      }
      const coproprietes = await this.$api.getElasticCoproprietes(query);

      this.options = coproprietes.map((el, index) => ({
        label:
          el.immatcopropriete__identification__adresseprincipale__flatadresse
            .raw,
        value: el.id.raw,
        address:
          el.immatcopropriete__identification__adresseprincipale__flatadresse
            .raw || "-",
        nom_copropriete:
          el.immatcopropriete__identification__nomcopropriete.raw || "-",
        syndic: el.raisonsocialeounomsyndic.raw || "-",
        immatcopropriete__identification__numimmatriculationcopropriete:
          el.immatcopropriete__identification__numimmatriculationcopropriete
            .raw || "-",
      }));
      this.loadingMessage = ""
      this.loading = false;
    },
    flush() {
      this.elasticCoproprieteSelected = " ";
      this.coproprieteSelected = {};
      this.record_id = "";
      this.options = [];
      this.send_to = "";
      Object.keys(this.answers).map((key) => {
        const boolean_fields = [
          "q_respect_egibilite",
          "q_connaissance",
          "q_renseignements",
          "q_connaissance_cg",
          "q_circonstances",
          "q_mandat_exclusif",
          "souhait_souscrire_ass",

          "flagEquipementsCom",
          "flagInsalubritePartiesCom",
          "flagMandatAdhoc",
          "flagOrdonnanceCarence",
          "flagPerilPartiesCom",
          "Syndic_professionnel",
          "Syndic_cooperatif",
          "Residence_de_service",
        ];
        if (boolean_fields.includes(key)) this.answers[key] = false;
        else this.answers[key] = "";
      });
    },
    async downloadPreFilled() {
      if (this.record_id) {
        const document_url = await this.$api.getPrefilledDocument(
          this.record_id
        );
        window.open(document_url, "_self");
      } else {
        window.open(
          "https://docs.google.com/uc?export=download&id=1O_8bUGZf2yx1SDr-3i9zb5et_zJNHozS",
          "_self"
        );
      }
    },
    async getRecord() {
      const ret = await this.$api.getSouscription(this.record_id);
      return ret;
    },
    async poller(activate) {
      if (activate) {
        if (!this.polling) {
          this.polling = setInterval(async () => {
            const data = await this.getRecord();
            if (data.Status == "3.Signed") {
              this.poller(false);
              this.nextStep();
            }
          }, 5000);
        }
      } else {
        clearInterval(this.polling);
        this.polling = null;
      }
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const {select} = this.$refs;
          const input = select.$el.querySelector('.el-input__inner');
          input.removeAttribute('readonly');
        }
      });
    },
    async send_to_fn() {
      console.log(this.send_to)
      console.log(this.sent)
      if (this.sent) return;
      if (this.sent == "pending") return;
      if (this.send_to) {
        this.sent = "pending";
        await this.$api.send_to(this.record_id, this.send_to);
        this.sent = true;
        this.send_to = "";
      }
    }
  },
  watch: {
    step(newStep, oldStep) {
      if (newStep == "yousign") {
        this.poller(true);
      } else if (oldStep == "yousign") {
        this.poller(false);
      }
    },
  },
  async beforeMount(){
    const rccs_id = this.$route.query.rccs_id
    const step = this.$route.query.step
    if (step && this.stepList.find( e => e == step ))
      this.step = this.$route.query.step
    if (rccs_id || RECORD_ID) {
      this.record_id = RECORD_ID || rccs_id
      const ret = await this.getRecord()
      Object.keys(this.answers).map( key => {
        if (ret[key]) this.answers[key] = ret[key]
      })
    }
  },
  async mounted() {
    if (this.step == "yousign") this.poller(true);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
h1,
h2 {
  margin-top: 0;
}

.soumettre {
  margin: 0px;
}


.app-bar {
  img {
    height: 45px;
    cursor: pointer;
  }
}
.inner-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  img {
    width: 100%;
    z-index: 4;
    height: 35vh;
    object-fit: cover;
  }
}
.inner-menu {
  position: relative !important;
}
.type-form {
  // margin-top: 10vh;
  min-height: 100vh;
  height: 10 0%;
  width: 100%;
  background-color: #f9f9f9;
}

.inner-form {
  margin-bottom: 3rem;
  position: relative;
  @include small {
    margin-bottom: 1rem;
  }
}
.container {
  padding: 1rem;
}

.section-typeform {
  background-color: white;
  border-radius: 50px;
  z-index: 7;
  position: absolute;
  margin: 30px 0;
  box-shadow: 0 2px 5px rgba($secondary, 0.2);
  top: 10vh;
  padding: 50px;
  width: 90%;
  @include small {
    left: 0;
    right: 0;
    margin: 20px auto;
    border-radius: 50px;
    max-width: 97vw;
    padding: 30px 10px;
  }
}
.table_elastic {
  width:100%;
  @include small {
    .table_delete {
      display: none;
    }
  }
  > div {
    display:flex;
    justify-content:space-between;
    line-height:24px;
  }
  .nom{
    font-weight:700;
  }
  .address{
    font-size:0.75rem;
    
  }
  .syndic{
    
    }
  .numero_immat{
    font-size:0.75rem;
    // font-style: italic;  
  }
}

.section-loading{
  background-color: $secondary;
  display: flex;
  border-radius: 200px;
  z-index: 7;
  width: 150px;
  height: 150px;
  position: absolute;
  margin: 30px 0;
  box-shadow: 0 2px 100px rgba($secondary, 0.8);
  top: 50vh;
  left: 50%;
  margin-left: -150px;
  margin-top: -150px;
  padding: 50px;
  z-index:1;
  // &::after {
    //   content: "⏰ Quelques instants, nous cherchons vos informations dans les registres de copropriétés.";
  //   font-family: Nunito, Inter, Helvetica, Arial, sans-serif;
  //   width: 150%;
  //   left: -25%;
  //   position: absolute;
  //   top: 300px;
  // }
  img {
    width: 100%;
  }
  @include small {
    width: 80px;
    height: auto;
    top: 50vh;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    padding: 20px;
    &::after {
      top: 100px;
      font-size: 0.9rem;
    }
  }
}

.section-loading-message {
  position:absolute;
  top: 65vh;
  width:100%;
  z-index:2;
  @include small {
    top: 55vh;
  }
}

.label {
  margin-top: 30px;
  text-align: left;
  font-weight: 700;
}

.help {
  text-align: left;
  margin: 5px 0;
  font-size: 0.8rem;
}

.w-50 {
  max-width: 700px;
}
.documents {
  li {
    margin: 10px 0;
    list-style: none;
    a {
      text-decoration: none;
    }
    a:hover {
    }
  }
  text-align: left;
  list-style: inside;
}
.ass-input {
  text-align: left !important;
  display: block !important;
  input {
    border-radius: 10px !important;
    // background-color: transparent;
  }
}

.el-select::v-deep {
  width: 100%;
}

.yes-no-choice {
  text-align: left;
  margin-top: 15px;
  .second {
    margin-top: 10px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.checkbox {
  margin-top: 30px;
  text-align: left;
}

.checkbox-item {
  align-items: flex-start !important;
  text-align: left !important;
  height: 100% !important;
  margin: 10px 0;
  // background-color:red;
}
.checkbox-item::v-deep .el-checkbox__label {
  // background-color:blue;
  white-space: normal;
  text-align: left;
  height: 100% !important;
  text-transform: none !important;
}

.select_elastic {
  display: flex;
  flex-direction: row;
  align-content: center;
  &:hover {
    .select_elastic__item {
      font-weight: 800;
    }
  }
  .label {
    padding-right: 40px;
  }
  table {
    padding: 5px;
    tr td {
      line-height: 16px;
    }
  }
}
.select_elastic.uneven {
  background: $secondary-lighten;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-content: center;
  justify-content: center;
  margin: 40px 0;
  @include small {
    flex-direction: column-reverse;
  }
}

.no-wrap {
  flex-direction: row;
  flex-wrap: nowrap;
}

.logo {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  width: 5rem;
  cursor: pointer;
  @include small {
    visibility: hidden;
  }
}
.icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.garantie__table_header::v-deep th .cell {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include small {
    word-break: keep-all !important;
  }
}
.question {
  text-align: left;
  width: 100%;
}

.yousign_iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.5);
  z-index: 10;
  // padding: 20px 0;
  iframe {
    transform: translateY(20px);
    width: 100%;
    max-width: $container-width;
    height: 90%;
    border-radius: 10px;
  }
}
.mail_send {
    display:flex;
    flex-direction:row;
    flex-wrap: nowrap; 
    * {
      margin:0px;
    }
    button {
      height:34px;
      min-width:80px;
    }
    align-items:end;
    @include small {
      flex-direction: row;
  }
}
.mt-3{
  margin-top: 3rem;
}
</style>
