<template>
	<button :class="classType" @click="fn"><slot default /></button>
</template>

<script>
export default {
	props: {
		fn: Function,
		classType: {
			default: 'primary',
			type:String,
		}
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

button {
	font-size: 16px;
	min-width: 250px;
	border-radius: 10px;
	height: 45px;
	border: 0;
	cursor: pointer;
	transition: all 0.2s;
	&.primary {
		background-color: $secondary-darken;
		color: white;
		&:hover {
			background-color: $secondary;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
		}
	}
	&.secondary {
		background-color: white;
		border: 1px solid $secondary;
		color: $secondary;
		&:hover {
			background-color: lighten($secondary, 45%);
			box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
		}
	}
}
</style>
