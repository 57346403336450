<template>
  <Menu>
    <!-- <template v-slot:default>
      <p @click="pushTo('blog')">Blog</p>
    </template> -->
    <template v-slot:cta>
      <Button class="primary" @click="pushTo('souscrire')"
        >Demander un devis</Button
      >
    </template>
  </Menu>
</template>

<script>
import Menu from "@/components/molecules/Menu.vue";
import Button from "@/components/atoms/Button.vue";

import router from "@/router";
export default {
  components: {
    Menu,
    Button,
  },
  methods: {
    pushTo: function (url) {
      if (url == "souscrire") router.push({ name: "Souscription" });
      else if (url == "contact")
        window.open("https://airtable.com/shrcmE7dpUBW9qB4O");
      // else if (url == "blog") window.open("/blog", "_self");
    },
  },
};
</script>
