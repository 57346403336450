<template>
  <div class="container inner-form">
    <el-form ref="ruleForm" :model="ruleForm2" :rules="rules">
      <el-form-item label="Activity name" prop="email">
        <el-input type="text" placeholder="Email" v-model="ruleForm2.email2" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm(ruleFormRef)"
          >Create</el-button
        >
      </el-form-item>
    </el-form>
    <el-form ref="ruleForm" :model="ruleForm2" :rules="rules">
      <el-form-item label="User Id" prop="email2">
        <el-input
          type="text"
          placeholder="Email"
          v-model="ruleForm2.email3"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">Login</el-button>
        <el-button @click="resetForm">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rules: {
        email2: [
          {
            required: true,
            message: "Please input Activity name",
            trigger: "blur",
          },

          {
            min: 3,
            max: 5,
            message: "Length should be 3 to 5",
            trigger: "blur",
          },
        ],
      },
      answers: {},
      ruleForm2: {
        email3: "",
        email2: "",
        email: "",
      },
    };
  },

  methods: {
    async submitForm(formEl) {
      this.$refs;
      console.log(
        this.$refs.ruleFormRef.validate((valid, fields) => {
          console.log(valid);
          console.log(fields);
        })
      );
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          console.log("submit!");
        } else {
          console.log("error submit!", fields);
        }
      });
    },
  },
};
</script>
