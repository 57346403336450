<template>
	<Menu />
	<iframe class="airtable-embed" :src="url"></iframe>
	<Footer />
</template>

<script>
import Menu from "@/components/organism/Menu.vue";
import Footer from "@/components/organism/Footer.vue";

export default {
	components: {
		Menu,
		Footer,
	},
	props: {
		url: String,
	},
};
</script>

<style>
.airtable-embed {
	padding-top: 80px;
	background: transparent;
	border: 1px solid #ccc;
	height: 95vh;
	width: 100%;
}
</style>
