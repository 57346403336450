<template>
  <section class="footer">
    <div class="container footer__content">
      <img
        @click="pushTo('Home')"
        class="hero__right"
        alt="BL logo"
        src="@/assets/logo-assynco_white.svg"
      />
      <div class="footer__content__link">
        <div>
          <p @click="pushTo('Mentions Legales')">Mentions légales</p>
          <p @click="linkTo('contact')">Nous contacter</p>
          <a href="mailto:info@assynco.com">info@assynco.com</a>
          <a href="tel:01 86 65 07 96">01 86 65 07 96</a>
        </div>
        <p @click="linkTo('souscrire')">Le produit d'assurance</p>
        <p>Notre politique de confidentialité</p>
      </div>
      <div class="divider" />
      <p class="footer__last">
        Assynco est une marque commerciale de Top Bridging, SASU de courtage en
        assurances au capital de 5,000 €, dont le siège social est situé 102
        avenue des Champs Élysées,75008 Paris au numéro 811 625 821 R.C.S. Paris
        – Immatriculée à l’ORIAS sous le numéro <b>15006282</b>
      </p>
    </div>
  </section>
</template>

<script>
import router from "@/router";

export default {
  methods: {
    pushTo(name) {
      router.push({ name });
      window.scrollTo(0, 0);
    },
    linkTo(url) {
      if (url == "souscrire") router.push({ name: "Souscription" });
      else if (url == "contact")
        window.open("https://airtable.com/shrcmE7dpUBW9qB4O");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_variables.scss";

.footer {
  padding-top: 40px;
  background-image: url("../../assets/houses.png") ;
  background-size: auto 100% ;
  background-color: $secondary-darken;
  &__content {
    color: white;
    text-align: left;
    img {
      max-width: 200px;
      cursor: pointer;
    }
    &__link {
      display: flex;
      justify-content: space-between;
      p,a {
        cursor: pointer;
        color: white;
        display:block;
        text-decoration: none;
        margin:16px 0;
        &:hover {
          text-decoration: underline;
        }
      }
      a {
        // todo
      }
    }
  }
  .divider {
    width: 100%;
    height: 0.1px;
    background-color: white;
  }
  &__last {
    margin-top: 15px;
    font-size: 10px;
  }
}
</style>
