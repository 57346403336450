<template>
  <router-view></router-view>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
export default {
  mounted() {
      AOS.init()
    }
};
</script>

<style lang="scss">
.el-select__popper {
  max-width: 90vw;
}
</style>