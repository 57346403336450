<template>
	<Menu />
	<div class="container mentions-legales">
		<h2>Mentions légales</h2>
		<p>Assynco est une marque commerciale de Top Bridging, SASU de courtage en assurances au capital de 5,000 €</p>
		<p>Siège social : 102 avenue des Champs Élysées,75008 Paris</p>
		<p>811 625 821 RCS Paris – Immatriculée à l’ORIAS sous le numéro 15006282</p>
		​
		<p>Président : Philippe Marchal</p>
		​
		<h2>Respect de la vie privée et de la loi "Informatique et Libertés"</h2>
		<p>
			Nous tenons à vous rappeler notre engagement à respecter scrupuleusement la confiance que vous nous accordez et à appliquer les obligations de
			la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. Conformément à la loi Informatique et Libertés, vous
			disposez d'un droit d'accès, de rectification et d'opposition aux données vous concernant que vous pouvez exercer en nous écrivant à l'aide de
			notre formulaire en ligne, ou par courrier adressé à notre attention, en mentionnant vos noms, prénoms et adresse complète.
		</p>

		<h2>Cookies</h2>
		<p>
			Certaines pages de notre site sont conçues pour être attentives aux besoins de nos visiteurs. C'est entre autres pour cela que nous pouvons
			implanter des cookies sur votre micro-ordinateur. Le cookie a pour but de signaler votre passage sur notre site. Les cookies ne sont donc
			utilisés par notre site que dans le but d'améliorer le service personnalisé qui vous est destiné. Vous pouvez empêcher cette collecte en
			désactivant cette fonction dans votre logiciel de navigation. Même lorsque cette fonction est désactivée, vous gardez la possibilité d'accéder à
			notre site.
		</p>

		<h2>Liens hypertextes</h2>
		<p>
			Des liens hypertextes peuvent renvoyer vers d'autres sites. Nous n'exerçons aucun contrôle sur les contenus desdits sites et dégageons toute
			responsabilité dans le cas où le contenu desdits sites contreviendrait aux dispositions légales et réglementaires en vigueur.
		</p>

		<h2>Propriété intellectuelle</h2>
		<p>
			En accord avec les lois régissant la propriété des droits littéraires et artistiques ou autres droits similaires, tous les éléments, marques,
			dénominations, photographies, dessins, modèles, logos, graphiques, codes couleurs, etc. se trouvant dans le présent site sont la propriété
			exclusive des entités concernées, de l'éditeur ou de ses partenaires ou fournisseurs, celle-ci ou ceux-ci ne concédant aucune licence, ni aucun
			autre droit que celui de consulter le site.
		</p>

		<p class="footnote">
			La reproduction ou l'utilisation de tout ou partie de ces éléments est seulement autorisée aux fins exclusives d'information pour un usage
			personnel et privé, toute reproduction et toute utilisation de copies réalisées à d'autres fins étant expressément interdites. Toute autre
			utilisation est constitutive de contrefaçon et sanctionnée au titre de la Propriété Intellectuelle, sauf autorisation préalable et écrite de
			l'éditeur.
		</p>
	</div>
	<Footer />
</template>
<script>
import Menu from "@/components/organism/Menu.vue";
import Footer from "@/components/organism/Footer.vue";
export default {
	components: {
		Menu,
		Footer,
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
.mentions-legales {
	padding-top: 80px;
}
</style>
