import axios from "axios";
import envService from './env'

export default function apiService () {
    const env = envService()
    const options = { headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }}

    const updateSouscription = async (record_id, data) => {
        const url = `${env.URL_API}/rccs${'/' + record_id}`;
        try {
            console.log("===============");
            console.log(url);
            console.log("===============");
            const ret = await axios.patch(url, data, options);
            return ret.data.id
        } catch(error) {
            return false
        }
    }

    const generateSignatureSouscription = record_id => {
        const url = `${env.URL_API}/rccs/${record_id}/sign`;
        try {
            return axios.get(url, options);
        } catch(error){
            return false
        }
    }
    const generateDocumentSouscription = record_id => {
        const url = `${env.URL_API}/rccs/${record_id}/generate`
        try {
            return axios.get(url, options);
        } catch(error){
            return false
        }
    }

    const getCopropriete = async copropriete_id => {
        const url = `${env.URL_API}/rccs/copropriete/${copropriete_id}`;
        try {
            const ret = await axios.get(url, options);
            return ret.data
        } catch(error) {
            return error
        }
    }

    const getSouscription = async record_id => {
        console.log(record_id)
        const url = `${env.URL_API}/rccs/${record_id}`;
        try {
          const ret = await axios.get(url, options);
          console.log(url)
          console.log(url)
          console.log(ret)
          return ret.data;
        } catch (error) {
          return error;
        }
    }

    const getElasticCoproprietes = async query => {
        const url = env.URL_ELASTIC
        const options = { headers: env.URL_ELASTIC_HEADER	}
        const data = { query, page: { size:5 } }
        const ret = await axios.post(url, data, options);
        return ret.data.results
    }

    const getPrefilledDocument = async record_id => {
      const url = `${env.URL_API}/rccs/${record_id}/generate_tmp`;
      try {
        const ret = await axios.get(url, options);
        return ret.data;
      } catch (error) {
        return error;
      }
    }

    const downloadFile = async url => {
        const ret = await axios.get(url)
        return ret.data;
    }

    const send_to = async (record_id, email) => {
        const url = `${env.URL_API}/rccs/${record_id}/send_to`;
        const data = { email }
        try {
            const ret = await axios.post(url, data);
            return ret.data.id
        } catch(error) {
            return false
        }
    }
    return {
      generateSignatureSouscription,
      generateDocumentSouscription,
      getCopropriete,
      updateSouscription,
      getSouscription,
      getElasticCoproprietes,
      getPrefilledDocument,
      downloadFile,
      send_to,
    };
}
