<template>
	<Menu />
  <div class="coproprietes">
    <div class="coproprietes__inner">
      <h1>Choisissez votre copropriété</h1>
      {{elasticCoproprieteSelected}}
      <el-select
              class="select"
              v-model="elasticCoproprieteSelected"
              filterable
              remote
              placeholder="Rechercher ici"
              :remote-method="elasticCoproprieteSearch"
              :loading="loading"
              ref="select"
              multiple
              :multiple-limit="1"
            >
              <el-option
                v-for="(item, index) in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                style="height: auto"
                :class="`select_elastic ${index % 2 ? 'uneven' : ''}`"
              >
                <table class="table_elastic">
                  <div>
                    <div class="nom">{{ item.nom_copropriete }}</div>
                    <div class="syndic">{{ item.syndic }}</div>
                  </div>
                  <div>
                    <div class="address">{{ item.address }}</div>
                    <div class="numero_immat">{{ item.immatcopropriete__identification__numimmatriculationcopropriete }}</div>
                  </div>

                </table>
              </el-option>
            </el-select>
            <Button class="rechercher" :fn="rechercher">Rechercher</Button>
    </div>

  </div>
	<Footer />
</template>

<script>

import Menu from "@/components/organism/Menu.vue";
import Footer from "@/components/organism/Footer.vue";
import router from "@/router";
import Button from "@/components/atoms/Button.vue";


export default {
  components: {
    Menu,
    Footer,
    Button,

  },
  name: "Home",
  data() {
    return {
      loading: false,
      elasticCoproprieteSelected: "",
      coproprieteSelected: {},
      options:[],
    }
  },
  methods: {
    rechercher(){
      console.log('toto')
      router.push({name: "CoproprieteChoisie",  params: { coproproprieteId: this.coproprieteSelected }})
    },
    async elasticCoproprieteSearch(query) {
      const coproprietes = await this.$api.getElasticCoproprietes(query);

      this.options = coproprietes.map((el, index) => ({
        label:
          el.immatcopropriete__identification__adresseprincipale__flatadresse
            .raw,
        value: el.id.raw,
        address:
          el.immatcopropriete__identification__adresseprincipale__flatadresse
            .raw || "-",
        nom_copropriete:
          el.immatcopropriete__identification__nomcopropriete.raw || "-",
        syndic: el.raisonsocialeounomsyndic.raw || "-",
        immatcopropriete__identification__numimmatriculationcopropriete:
          el.immatcopropriete__identification__numimmatriculationcopropriete
            .raw || "-",
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.coproprietes {
  // margin-top:$menuHeight;
  height:100vh;
  width:100vw;
  display:flex;
  justify-content: center;
  align-content: center;
  &__inner {
    display:flex;
    flex-direction: column;
    justify-content: center;
    gap:30px;
  }
  ::after{
    height:20%;
    content:' ';
  }
  background-image: url("../assets/houses.png") ;
  background-size: 1100px auto;
  background-position:bottom;
  background-repeat: repeat-x;


}

.select{
  width:100%;
  // margin-bottom:30px;
}

.checkbox-item {
  align-items: flex-start !important;
  text-align: left !important;
  height: 100% !important;
  margin: 10px 0;
  // background-color:red;
}

.checkbox-item::v-deep .el-checkbox__label {
  // background-color:blue;
  white-space: normal;
  text-align: left;
  height: 100% !important;
  text-transform: none !important;
}

.select_elastic {
  display: flex;
  flex-direction: row;
  align-content: center;
  &:hover {
    .select_elastic__item {
      font-weight: 800;
    }
  }
  .label {
    padding-right: 40px;
  }
  table {
    padding: 5px;
    tr td {
      line-height: 16px;
    }
  }
}

.select_elastic.uneven {
  background: $secondary-lighten;
}
.table_elastic {
  width:100%;
  @include small {
    .table_delete {
      display: none;
    }
  }
  > div {
    display:flex;
    justify-content:space-between;
    line-height:24px;
  }
  .nom{
    font-weight:700;
  }
  .address{
    font-size:0.75rem;
    
  }
  .syndic{
    
    }
  .numero_immat{
    font-size:0.75rem;
    // font-style: italic;  
  }
}

// https://github.com/rendora/rendora
</style>