import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import fr from "element-plus/es/locale/lang/fr";

import 'element-plus/dist/index.css'
import { Paperclip } from '@element-plus/icons-vue';
import service from '@/services'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import AOS from 'aos'
import 'aos/dist/aos.css'

const app = createApp(App)

app.config.globalProperties = {...app.config.globalProperties, ...service}

Sentry.init({
    app,
    dsn: "https://2c18504890494ed5bc1c52ea6b1eb000@o1394734.ingest.sentry.io/6716900",
    integrations: [
        new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "assynco.com", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    });

app.use(ElementPlus, {
    locale:fr
})
    .component('PaperclipIcon', Paperclip)
    .use(store)
    .use(router)
    .mount('#app')