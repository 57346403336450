<template>
  <Menu />
  <section class="container hero">
    <div class="hero__left" data-aos="fade-right">
      <h1>L'assurance qui protège les membres de votre conseil syndical</h1>
      <h3>
        Souscrivez une assurance Responsabilité des Membres du Conseil Syndical
      </h3>
      <ul>
        <li>
          Prise en charge des <b>frais de défense</b> (civil, pénal et
          administratif)
        </li>
        <li>Prise en charge des <b>conséquences pécuniaires</b></li>
        <li>Garanties <b>additionnelles</b> (assistance, prévention)</li>
      </ul>
      <div class="cta flex-row">
        <button class="primary" @click="pushTo('souscrire')">
          Demander un devis
        </button>
        <button class="secondary" @click="pushTo('contact')">
          Nous contacter
        </button>
      </div>
    </div>
    <div class="hero__right">
      <img alt="BL logo" src="@/assets/assynco-home.png" data-aos="fade-left" />
    </div>
  </section>
  <section class="legal">
    <div class="container legal__content">
      <img alt="valid" src="@/assets/valid.svg" />
      <div class="text">
        <h2>Une obligation légale depuis la loi du 12 septembre 2020</h2>
        <p>
          Depuis la loi ALUR, l’article Art. 21-4 oblige les syndicats des
          copropriétaires à souscrire pour chacun des membres du conseil
          syndical, une assurance de responsabilité civile.
        </p>
        <p>
          Assynco vous propose un produit spécialement conçu pour répondre à
          cette obligation.
        </p>
      </div>
      <span>
        <button class="primary" @click="pushTo('souscrire')">
          Souscrire l’assurance
        </button>
      </span>
    </div>
  </section>
  <section class="container risque">
    <h2>
      Quelles sont les couvertures additionnelles gérées par l’assurance ?
    </h2>
    <div class="risque__list">
      <div>
        <img class="" alt="BL logo" src="@/assets/risques/reputation.svg" />
        <p>Atteinte à la réputation</p>
      </div>
      <div>
        <img class="" alt="BL logo" src="@/assets/risques/attenuation.svg" />
        <p>Frais d’atténuation du risque</p>
      </div>
      <div>
        <img class="" alt="BL logo" src="@/assets/risques/garde_a_vue.svg" />
        <p>Frais d’assistance de garde à vue</p>
      </div>
      <div>
        <img class="" alt="BL logo" src="@/assets/risques/defense.svg" />
        <p>Frais de défense en cas de réclamations conjointes</p>
      </div>
      <div>
        <img class="" alt="BL logo" src="@/assets/risques/psychologique.svg" />
        <p>Soutien psychologique</p>
      </div>
    </div>
    <p>
      Accéder à la <a href="https://docs.google.com/uc?export=download&id=1VNFVdnPkCnQfoo3rJ3imqsaeR-Ks-ins">police</a> complète pour connaître davantage les risques
      couverts par la police d’assurance
    </p>
  </section>
  <section class="container description">
    <div>
      <div>
        <h2>Quelle est cette assurance ?</h2>
        <p>
          Les membres des Conseils syndicaux de Copropriété évoluent dans un
          environnement juridique où leur responsabilité personnelle peut être
          recherchée dans l’exécution de leur mandat pour le compte de la
          copropriété ainsi qu’à l’égard des tiers.
        </p>
      </div>
      <div class="description__img">
        <img src="@/assets/description-1.png" alt="description 1" />
      </div>
    </div>
    <div>
      <div>
        <h2>Qui est assuré ?</h2>
        <p>
          ‍💼 Les membres personne physique passés, présents ou futurs du
          conseil syndical la copropriété souscriptrice <br /><br />
          La personne physique représentante légale ou statutaire, ou le fondé
          de pouvoir spécialement habilité à cet effet, de toute personne morale
          membre du conseil syndical de la copropriété souscriptrice.<br /><br />
          🏠 La qualité d’assuré est étendue aux héritiers, les légataires, les
          représentants légaux et les ayants cause des membres du conseil
          syndical ainsi que les époux, concubins ou partenaires liés par un
          pacte civil de solidarité dans le cadre d’une réclamation visant à
          obtenir réparation sur les biens communs ou indivis avec un membre du
          conseil syndical.
        </p>
      </div>
      <div class="description__img">
        <img src="@/assets/description-2.png" alt="description 1" />
      </div>
    </div>
    <div>
      <div>
        <h2>Pour quel type de faute ?</h2>
        <p>
          Tout manquement aux obligations légales ou réglementaires, toute faute
          de gestion commise par imprudence ou négligence, par omission, par
          erreur, par déclaration inexacte et, en général tout acte fautif.<br /><br />
          En général, tout acte fautif commis par cet assuré dans le cadre de
          ses fonctions au sein du conseil syndical de la copropriété
          souscriptrice.<br /><br />
          👍 Une simple réclamation allégant d'une faute suffit à déclencher la
          garantie
        </p>
      </div>
      <div class="description__img">
        <img src="@/assets/description-3.png" alt="description 1" />
      </div>
    </div>
  </section>
  <section class="step">
    <div data-aos="fade-right">
      <h2>Les étapes de souscription en quelques clics</h2>
      <div class="container step__list">
        <div class="step__list__item">
          <p class="step__list__number">01</p>
          <p>Envoyez nous vos informations</p>
        </div>
        <p class="comma">●●●●●</p>
        <div class="step__list__item">
          <p class="step__list__number">02</p>
          <p>Nous cotons le contrat et vous renvoyons pour signature</p>
        </div>
        <p class="comma">●●●●●</p>
        <div class="step__list__item">
          <p class="step__list__number">03</p>
          <p>Vous signez le contrat</p>
        </div>
        <p class="comma">●●●●●</p>
        <div class="step__list__item">
          <p class="step__list__number">04</p>
          <p>Vos membres sont assurés!</p>
        </div>
      </div>
      <div class="cta">
        <button class="primary" @click="pushTo('souscrire')">
          Demander un devis
        </button>
        <button class="secondary" @click="pushTo('contact')">
          Des questions ?
        </button>
      </div>
    </div>
  </section>
  <section class="container">
    <h2>Nos partenaires</h2>
    <el-carousel class="test" arrow="never" interval="6000" indicator-position="outside">
      <el-carousel-item key="1" >
        <div class="partnership">
          <div class="partnership__logos">
            <img alt="BL logo" src="@/assets/logo-aig.png" data-aos="fade-left" />
          </div>
          <div class="partnership__explanation">
            <h4>L’expertise AIG</h4>
            <ul>
              <li>Leader depuis plus de 35 ans sur le marché français, AIG accompagne des dirigeants de multinationales (nous sommes présents en France sur 88% des programmes du CAC 40 placés en France / 56% des primary de ces programmes / 65% des excess) et de PME avec près de 200 programmes </li>
              <li>22.000 polices actuellement en portefeuille en Risques Financiers</li>
              <li>Une équipe de souscription de 32 personnes dédiées aux lignes financières en France</li>
            </ul>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item key="2">
        <div class="partnership">
          <div class="partnership__logos">
            <img alt="BL logo" src="@/assets/logo-unis.png" data-aos="fade-left" @click="console.log('toto')"/>
          </div>
          <div class="partnership__explanation">
            <h4>UNIS - Les professionnels de l'immobilier</h4>
            <ul>
              <li>L'UNIS est la première organisation de professionnels de l’immobilier, qui représente tous les métiers du secteur</li>
              <li>Agents immobiliers, gestionnaires locatifs, administrateurs de biens, experts immobiliers, promoteurs-rénovateurs. </li>
              <li>les 2 200 entreprises adhérentes de l‘UNIS (indépendants, réseaux et groupes) et leurs 20 000 salariés, font le choix de rejoindre une communauté de vision et de mettre en pratique une approche différente de l’immobilier</li>
            </ul>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

  </section>

  <Footer />
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/organism/Menu.vue";
import Footer from "@/components/organism/Footer.vue";
import router from "@/router";

export default {
  components: {
    Menu,
    Footer,
  },
  name: "Home",
  methods: {
    pushTo: function (url) {
      if (url == "souscrire") router.push({ name: "Souscription" });
      else if (url == "contact")
        window.open("https://airtable.com/shrcmE7dpUBW9qB4O");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
$url_check: "../assets/check.svg";

.hero {
  min-height: 100vh;
  display: flex;
  gap: 40px;
  align-items: center;
  &__right {
    text-align: right;
    width: 40%;
  }

  /* Content left box */
  &__left {
    width: 70%;
    display: flex;
    flex-direction: column;
    height: 100%;
    li {
      text-align: left;
      line-height: 1.5;
      list-style: url($url_check);
    }
    h3 {
      text-align: left;
      margin: 0;
      margin-left: 15px;
    }
    .cta {
      margin: 40px 0;
    }
    .hero__left__footer {
      font-size: 0.75rem;
      text-align: left;
      justify-items: self-end;
    }
  }
  @include small {
    min-height: 0;
    margin-top: 300px;
    padding-top: 30px !important;
    margin: 80px 0;
    flex-direction: column;
    padding-top: 700px;
    &__right {
      display: none;
    }
    > * {
      width: 100%;
    }
  }
}

.legal {
  background-color: #262a3e;
  padding: 80px 0;
  &__content {
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 40px;
    align-content: center;
    img {
      max-width: 110px;
    }
    .text {
      display: flex;
      flex-direction: column;
      text-align: left;
      h2 {
        margin: 0;
        align-items: flex-start;
      }
    }
    span {
      display: flex;
      align-items: center;
    }
    @include small {
      flex-direction: column;
      padding: 10px 10px;
    }
  }
}

.risque {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  > h2,
  > p {
    text-align: left;
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    > * {
      max-width: 150px;
    }
    p {
      text-align: center;
    }
  }
  @include small {
    flex-direction: column;
    padding: 10px 10px;
  }
}

.description {
  > * {
    display: flex;
    margin: 50px 0;
    gap: 50px;
    flex-direction: row-reverse;
    > * {
      width: 50%;
      img {
        width: 100%;
        max-width: 400px;
        height: auto;
      }
    }
  }
  > div:nth-child(2) {
    flex-direction: row;
  }
  @include small {
    > div:nth-child(2) {
      flex-direction: column;
    }
    > * {
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
  }
}

.step {
  background: url("../assets/cement.png");
  background-position: top;
  background-color: rgba(#c4c4c4, 0.3);
  padding: 40px 0;
  h2 {
    text-align: center;
  }
  &__list {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    > * {
      max-width: 250px;
    }
    &__number {
      font-size: 1.5rem;
      font-weight: 900;
      color: $secondary;
      text-align: center;
    }
    @include small {
      flex-direction: column;
      gap: 0;
      .comma {
        display: none;
      }
      &__item {
        width: 100%;
        max-width: none;
        display: flex;
        flex-wrap: nowrap;
        > p {
          display: inline;
          width: 600px;
        }
      }
    }
    .comma {
      font-size: 15px;
      width: 10px;
      color: $secondary;
      letter-spacing: 13px;
      margin-left: -85px;
      padding-top: 12px;
      // position:absolute;
    }
  }
  .cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  @include small {
    padding-bottom: 40px;
    .cta {
      gap: 15px;
    }
  }
}

.partnership {
  padding:40px 0;
  display:flex;
  justify-content: center;
  width:100%;
  gap:100px;
  &__explanation{
    width:auto;
    flex:2;
    
    @include small {
      display:none;
    }
  }
  h2 {
    text-align: center;
  }
  &__logos {
    width:100%;
    flex:1;
    align-items: center;
    display:flex;
    align-content: center;
    justify-content: center;
    img {
      height:auto;
      width:100%;
      min-width:300px;
      max-width:500px;
    }
    @include small {
      flex:none;
    }
  }
  
  h4,ul {
    text-align: left;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

button {
  font-size: 16px;
  width: 250px;
  border-radius: 10px;
  height: 45px;
  border: 0;
  cursor: pointer;
  transition: all 0.2s;
  &.primary {
    background-color: $secondary;
    color: white;
    &:hover {
      background-color: $secondary-darken;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
  }
  &.secondary {
    background-color: white;
    border: 1px solid $secondary;
    color: $secondary;
    &:hover {
      background-color: lighten($secondary, 45%);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
  }
}
.no-wrap {
  flex-wrap:nowrap;
}
</style>
