import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Coproprietes from "../views/Coproprietes.vue";
import Blog from "../views/Blog.vue";
import Legal from "../views/Legal.vue";
import NCG from "../views/NCG.vue";
import IFrame from "../views/IFrame.vue";
import TypeForm from "../components/TypeForm.vue";
import draft from "../components/draft.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/coproprietes",
    name: "Coproprietes",
    component: Coproprietes,
  },
  // {
  //   path: "/blog",
  //   name: "Blog",
  //   component: Blog,
  // },
  {
    path: "/mentions-legales",
    name: "Mentions Legales",
    component: Legal,
  },
  {
    path: "/ncg",
    name: "NCG",
    component: NCG,
  },
  {
    path: "/ncg-sinistre",
    name: "NCGSinistre",
    component: IFrame,
    props: {
      url: "https://airtable.com/embed/shrx9NKk7D6DYUf8v?backgroundColor=teal&viewControls=on",
    },
  },
  {
    path: "/ncg-police",
    name: "NCGPolice",
    component: IFrame,
    props: {
      url: "https://airtable.com/embed/shrUhe58Hvz7ZABYr?backgroundColor=teal&viewControls=on",
    },
  },
  {
    path: "/ncg-declaration",
    name: "NCGDeclaration",
    component: IFrame,
    props: {
      url: "https://airtable.com/embed/shrI6AWSE6qTZLcsW?backgroundColor=teal",
    },
  },
  {
    path: "/ncg-assurance",
    name: "NCGAssurance",
    component: IFrame,
    props: {
      url: "https://airtable.com/embed/shr5NKuSr8IetRacL?backgroundColor=pinkLight",
    },
  },
  {
    path: "/immoexpress-sinistre",
    name: "ImmoExpressSinistre",
    component: IFrame,
    props: {
      url: "https://airtable.com/embed/shrV6lrc6hmfA4aur?backgroundColor=teal&viewControls=on",
    },
  },
  {
    path: "/immoexpress-police",
    name: "ImmoExpressPolice",
    component: IFrame,
    props: {
      url: "https://airtable.com/embed/shrSxJjVn7RVw1kaj?backgroundColor=teal&viewControls=on",
    },
  },
  {
    path: "/souscription",
    name: "Souscription",
    component: TypeForm,
  },
  {
    path: "/draft2",
    name: "Draft2",
    component: draft,
  },
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
