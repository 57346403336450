export default function envService () {
    let URL_API
    if (window.location.hostname == 'localhost')
        URL_API = 'http://127.0.0.1:8080'
    else
        URL_API = 'https://assynco-rccs-dot-learned-age-237111.an.r.appspot.com'

    return {
        URL_API,
        URL_ELASTIC : 'https://coproprietes.ent.europe-west2.gcp.elastic-cloud.com/api/as/v1/engines/coproprietes/search',
        URL_ELASTIC_HEADER : { 
            Authorization: "Bearer " + "search-kp2z85wo8aiwt3zfq46if6s1",
            'Content-Type': 'application/json'
        },
        
    }
}
