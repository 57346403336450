<template>
	<div>
		<div class="menu">
			<div :class="'container' + openMenuState">
				<div @click="pushTo">
					<img alt="BL logo" src="@/assets/logo-assynco.svg" />
				</div>
				<div :class="'long-menu' + openMenuState">
					<slot default />
					<slot name="cta" />
				</div>
				<div class="menu-burger" @click="openMenuFn">
					<div :class="'menu-btn' + openMenuState">
						<div class="menu-btn__burger"></div>
					</div>
				</div>
			</div>
		</div>
		<div :class="openMenuState">
			<div class="menu-column">
				<div class="menu-list">
					<slot default />
					<slot name="cta" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, nextTick, onBeforeUnmount, computed } from "vue";
import router from "@/router";

const menuSet = () => {
	const desktopWidth = 940;
	const windowWidth = ref(window.innerWidth);
	const onResize = () => (windowWidth.value = window.innerWidth);
	const addEventWindow = async () => {
		await nextTick();
		window.addEventListener("resize", onResize);
	};
	const removeEventWindow = async () => {
		window.removeEventListener("resize", onResize);
	};
	onMounted(addEventWindow);
	onBeforeUnmount(removeEventWindow);
	/* openMenu */
	const openMenu = ref(false);
	const openMenuFn = () => {
		openMenu.value = !openMenu.value;
	};
	const openMenuState = computed(() => {
		if (windowWidth.value > desktopWidth) openMenu.value = false;
		return openMenu.value ? " open-menu" : "";
	});
	return {
		openMenuState,
		openMenuFn,
		openMenu,
	};
};

export default {
	setup() {
		const { openMenuState, openMenuFn, openMenu } = menuSet();

		return {
			openMenuState,
			openMenuFn,
			openMenu,
		};
	},
	methods: {
		pushTo() {
			router.push({ name: "Home" });
		},
	},
};
</script>
<style lang="scss">
@import "@/styles/_variables.scss";
.menu {
	height: $menuHeight;
	box-sizing: border-box;
	position: fixed;
	width: 100%;
	background: white;
	z-index: 2;
	top: 0;
	img {
		height: 45px;
		cursor: pointer;
	}
	> .container {
		display: flex;
		height: 100%;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		> .long-menu {
			display: flex;
			align-items: center;
			align-content: center;
			@include small {
				display: none;
			}
			> p {
				cursor: pointer;
				padding: 0 30px;
				margin: 0px;

				&:hover {
					color: $secondary;
				}
			}
		}
		> .menu-burger {
			display: none;
			@include small {
				display: flex;
			}
		}
	}
}

/* Menu BTN */
.menu-burger {
	cursor: pointer;
	height: 50px;
	position: relative;
	justify-content: center;
	align-items: center;
}
.menu-btn {
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease-in-out;
	.menu-btn__burger {
		position: relative;
		&,
		&::before,
		&::after {
			width: 30px;
			height: 2px;
			box-shadow: 0 2px 5px rgba($secondary, 0.2);
			background: $primary;
			border-radius: 5px;
			transition: all 0.5s ease-in-out;
		}
		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
		}
		&::before {
			transform: translateY(-12px);
		}
		&::after {
			transform: translateY(12px);
		}
	}
}
.menu-column {
	height: calc(100vh - #{$menuHeight});
	width: 100vw;
	position: fixed;
	top: $menuHeight;
	transform: translateY(-100vh);
	background-color: white;
	color: $primary;
	transition: all 0.7s;
	z-index: 1;
	.menu-list > p {
		display: block;
		cursor: pointer;
		box-sizing: border-box;
		padding: $padding;
		text-align: left;
		&:hover {
			background-color: rgba($primary, 0.2);
			font-weight: 700;
		}
	}
}
/* ANIMATION */
.open-menu {
	.menu-btn__burger {
		transform: translateX(-50px);
		background: transparent;
		box-shadow: none;
		&::before {
			transform: rotate(45deg) translate(35px, -35px);
		}
		&::after {
			transform: rotate(-45deg) translate(35px, 35px);
		}
	}
	.menu-column {
		transform: translateY(0);
		transition: all 0.7s;
	}
}
</style>
